import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Privacy Policy"
                    page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">This Privacy policy was published on April 1st, 2024.</h5>
                                    </div>

                                    <h4>General</h4>

                                    <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules made there under
                                        as applicable and the amended provisions pertaining to electronic records in various statutes as amended by
                                        the Information Technology Act, 2000. This electronic record is generated by a computer system and does not
                                        require any physical or digital signatures. This privacy policy document ("Privacy Policy") is published in
                                        accordance with the provisions of Rule 4 (1) of the Information Technology (Reasonable Security Practices
                                        and Procedures and Sensitive Personal Data or Information) Rules, 2011 which requires the publishing of a
                                        privacy policy for handling of or dealing in personal information including sensitive personal data or
                                        information.</p>

                                    <p>Thank you for visiting www.vgyani.com and/or the Vgyani-The Test Prep App mobile application (collectively
                                        referred to as the "Platform"). Vgyani-The Test Prep App is registered as Vgyani Edutainment Services Pvt.
                                        Ltd., a company within the meaning of the Companies Act, 2013 (CIN: U80902DL2022PTC395011) having Registered
                                        Office at Budh Vihar, Delhi (hereinafter, referred to as "we", "us", "our" and "Vgyani-The Test Prep App").
                                    </p>
                                    <p>We are committed to protecting and respecting the privacy of every person who shares information with it or
                                        whose information it receives. This Privacy Policy ("Privacy Policy") applies to the collection, receipt,
                                        storage, usage, processing, disclosure, transfer and protection ("Utilization") of your Personal Information
                                        (defined below) when the user of the platform ("User" or "you") uses our platform or avails any services
                                        offered by Vgyani-The Test Prep App through the platform.</p>

                                    <p>The capitalized terms that are used but not defined in this Privacy Policy will have the same meaning as in
                                        our Terms and Conditions. By using the services or by otherwise giving us your information, you will be
                                        deemed to have read, understood and agreed to the practices and policies outlined in this privacy policy and
                                        agree to be bound by the privacy policy. You hereby consent to our collection, use and sharing, disclosure
                                        of your information as described in this privacy policy. We reserve the right to change, modify, add or
                                        delete portions of the terms of this privacy policy, at our sole discretion, at any time. If you do not
                                        agree with this privacy policy at any time, do not use any of the services or give us any of your
                                        information. If you use the services on behalf of someone else (such as your child) you represent that you
                                        are authorised by such individual or entity to (I) Accept this privacy policy on such individual’s behalf,
                                        and (II) consent on behalf of such individual to our collection, use and disclosure of such individual’s
                                        information as described in this privacy policy.</p>

                                    <h4>Consent</h4>

                                    <p>You acknowledge that this Privacy Policy is a part of the User Terms of the platform and the other services,
                                        by accessing the platform or by otherwise providing Us with your Personal Information or by making use of
                                        the services provided by the platform, You unconditionally signify Your (i) assent to the Privacy Policy,
                                        and (ii) consent to the Utilisation of your Personal Information in accordance with the provisions of this
                                        Privacy Policy.</p>

                                    <p>You acknowledge that you are providing your Personal Information out of your free will.</p>

                                    <p>You have the option not to provide us the Personal Information sought to be collected. You will also have an
                                        option to withdraw your consent at any point, provided such withdrawal of the consent is intimated to us in
                                        writing at help.vgyani@gmail.com. If you do not provide us your Personal Information or if you withdraw the
                                        consent to provide us with your Personal Information at any point in time, please note that your withdrawal
                                        of consent, may result in Vgyani-The Test Prep App being unable to provide you with its services or to
                                        terminate any existing relationship Vgyani-The Test Prep App may have with you.</p>

                                    <p>Our platform is accessible to children belonging to the age group of 13 or 13+. Vgyani-The Test Prep App
                                        strongly encourages parents and guardians to supervise the online activities of their minor children and
                                        consider using parental control tools. These tools also can prevent minors from disclosing their name,
                                        address, and other personally identifiable information online without parental permission. Vgyani-The Test
                                        Prep App respects the privacy of minors who may inadvertently use the platform. We do not knowingly collect
                                        any Personal Information from children (without the consent of their parents/guardians). Please contact us
                                        at help.vgyani@gmail.com, if you are aware that we may have inadvertently collected Personal Information
                                        from a child, and we will delete that information as soon as possible.</p>

                                    <h4>Grievance Redressal</h4>

                                    <p>Vgyani-The Test Prep App provides multiple channels for you to request for support or to communicate any
                                        grievances you may have:</p>

                                    <p>By sending an email to help.vgyani@gmail.com</p>
                                    <p>Any of your queries & requests shall be responded to within 14 (Fourteen) working days of receipt.
                                        Resolution, appropriate and to your satisfaction shall be provided as soon as possible depending upon the
                                        nature of the request.</p>

                                    <p>We request you to please provide the following information in your complaint:-</p>
                                    <ul>
                                        <li>Identification of the information provided by you.</li>
                                        <li>Clear statement as to whether the information is personal information or sensitive personal information.
                                        </li>
                                        <li>Your address, registered telephone number or e-mail address.</li>
                                        <li>A statement that you have a good-faith belief that use of the information has been processed incorrectly
                                            or disclosed without authorization, as the case may be.</li>
                                        <li>A statement, under penalty of perjury, that the information in the notice is accurate, and that you are
                                            authorized to act on behalf of the owner of the right that is allegedly infringed.</li>
                                    </ul>
                                    <p>Vgyani-The Test Prep App may reach out to you to confirm or discuss certain details about your complaint and
                                        issues raised.</p>

                                    <p>Vgyani-The Test Prep App shall not be responsible for any communication, if addressed, to any non-designated
                                        person in this regard.</p>

                                    <p>You may also contact us in case you have any questions / suggestions about the Privacy Policy using the email
                                        Id mentioned above.</p>

                                    <h4>Personal information collected</h4>

                                    <p>In order to provide services to you we might require you to voluntarily provide us certain information that
                                        personally identifies you. You hereby consent to the collection of such information by Vgyani-The Test Prep
                                        App. The information that we may collect from you, or about you, may include but are not limited to, the
                                        following:</p>
                                    <p>Vgyani-The Test Prep App’s use of information received from Google APIs will adhere to Google API Services
                                        User Data Policy, including the Limited Use requirements.</p>

                                    <ul>
                                        <li>Username;</li>
                                        <li>Mobile Number;</li>
                                        <li>Class;</li>
                                        <li>Records of interaction with Vgyani-The Test Prep App’s representatives;</li>
                                        <li>Your usage details such as time, frequency, duration and pattern of use, features used and the amount of
                                            storage used;</li>
                                        <li>Internet protocol address, referring URL, files accessed, errors generated, time zone, operating system
                                            and other visitor details collected in Our log files, the pages of our platform that You visit, the time
                                            and date of Your visit, the time spent on those pages and other statistics ("Log Data"); and</li>
                                        <li>Any other information that is willingly shared by you. (collectively referred to as "Personal
                                            Information").</li>
                                    </ul>

                                    <h4>How we collect personal information ?</h4>

                                    <p>The methods by which We collect your Personal Information include but are not limited to the following:</p>
                                    <ul>
                                        <li>When you access our platform or use any features of our platform;</li>
                                        <li>When you provide your Personal Information to us;</li>
                                        <li>During the course of services provided to you by us;</li>
                                        <li>Through your computer/device, once you have granted permissions to our platform;</li>
                                        <li>Through Vgyani-The Test Prep App’s representatives;</li>
                                        <li>Through use of cookies (also discussed below); or</li>
                                    </ul>
                                    <p>We collect information that your browser/app sends whenever you visit our platform, such as the Log Data. In
                                        addition, we may use third party services to collect, monitor and analyse such Log Data. This information is
                                        kept completely secure.</p>

                                    <h4>Use of personal information</h4>

                                    <p>We recognise and acknowledge the value and importance of the protection of your Personal Information and
                                        ensure that the same will be used for the intended purpose(s).</p>

                                    <p>By virtue of this Privacy Policy, We will collect/receive/share/process/use/store your Personal Information
                                        in accordance with applicable laws to provide you with the services, enhance the user experience, manage our
                                        relationship with You, build user insights, analyse data for the introduction of new services and features,
                                        and as may be required to comply with any regulatory requirements and contractual obligations, which may
                                        inter alia include:</p>
                                    <ul>
                                        <li>To provide effective services;</li>
                                        <li>To resolve any customer support related issues;</li>
                                        <li>To operate and improve the platform;</li>
                                        <li>To perform research and analysis for our understanding, information, analysis, services and technologies
                                            in order to provide all users improved quality of service; and ensuring that the content and advertising
                                            displayed are customized to your interests and preferences;</li>
                                        <li>To contact you via phone, whatsapp, SMS or email for appointments, technical issues, payment reminders,
                                            obtaining feedback and other security announcements;</li>
                                        <li>To send promotional and marketing emails from us or any of our channel partners via WhatsApp, SMS, email
                                            or third-party communication services such as gupshup, Facebook etc.;</li>
                                        <li>To advertise products and services of Vgyani-The Test Prep App and third parties;</li>
                                        <li>To transfer information about you, if we are merged with another company;</li>
                                        <li>To share with our business partners for provision of specific services you have ordered so as to enable
                                            them to provide effective services to you;</li>
                                        <li> To administer or otherwise carry out our obligations in relation to any agreement you have with Us;
                                        </li>
                                        <li> For purposes of KYC verification;</li>
                                        <li> To build your profile/user account on the platform;</li>
                                        <li> To investigate, prevent, or take action regarding illegal activities, suspected fraud, violations of
                                            our Terms of Use, breach of our agreement with You or as otherwise required by law. (collectively
                                            referred to as "Purpose(s)").</li>
                                    </ul>
                                    <h4>Sharing and transferring of personal information</h4>

                                    <p>Your Personal Information may be used for various purposes including but not limited to the following:</p>
                                    <ul>
                                        <li> We may disclose Personal Information about you to our representatives and other third parties to comply
                                            with applicable laws and regulations. We also reserve the right to disclose your Personal Information to
                                            third parties in an anonymous or aggregate form, to understand customer trends and patterns and manage
                                            and improve our business relationships. We provide your Personal Information to our business partners,
                                            representatives and third party processors to administer and process for the purposes notified to you in
                                            this Privacy Policy, and we may also share such details with third parties (such as auditors or legal
                                            advisors) to obtain professional advice. Any such processing will be governed by an agreement in the
                                            form required by law, preserving any and all of your statutory data protection rights. You authorize us
                                            to exchange, transfer, share, part with all or any of your Personal Information, across borders and from
                                            Your country to any other countries across the world with our affiliates/agents/third party service
                                            providers/partners/banks and financial institutions for the Purposes specified under this Privacy Policy
                                            or as may be required by applicable law.</li>
                                        <li> You hereby consent and authorize us to publish feedback obtained by you on our platform.</li>
                                        <li> User's financial information are transacted upon secure platforms of approved payment gateways which
                                            are digitally under encryption, thereby providing the highest possible degree of care as per current
                                            technology. However, User is advised to exercise discretion while saving the payment details.</li>
                                        <li> You acknowledge that Vgyani-The Test Prep App may be obligated by law to disclose or transfer your
                                            Personal Information with Courts and Government agencies in certain instances such as for verification
                                            of identity, or for prevention, detection, investigation, prosecution, and punishment for offences, or
                                            in compliance with laws. You hereby consent to disclosure or transfer of your Personal Information in
                                            these instances.</li>
                                        <li> Notwithstanding the above, we are not responsible for the confidentiality, security or distribution of
                                            Your Personal Information by third-parties outside the scope of our agreement with such third-parties.
                                            Further, We will not be responsible for any breach of security or for any actions/omissions of any
                                            third-parties or events that are beyond the reasonable control of us including but not limited to, acts
                                            of government, computer hacking, unauthorized access to computer data and storage device, computer
                                            crashes, breach of security and encryption, poor quality of Internet service or telephone service of the
                                            User etc.</li>
                                        <li> We may share your Personal Information with our other corporate and/or associate entities and
                                            affiliates to (i) help detect and prevent identity theft, fraud and other potentially illegal acts and
                                            cyber security incidents, and (ii) help and detect co-related/related or multiple accounts to prevent
                                            abuse of our services.</li>
                                        <li> Your KYC will be shared with our partners, associate, subsidiaries or group company for purpose of
                                            verification. By agreeing to terms and condition you are hereby authorizing Vgyani Edutainment Services
                                            Pvt. Ltd. to share the KYC details.</li>
                                    </ul>

                                    <h4>Advertising Policy for VGYANI - The Test Prep App</h4>

                                    <p>VGYANI - The Test Prep App is committed to delivering a high-quality, educational experience while respecting the privacy and data preferences of our users, aged 13 to 25 years. Our app includes advertisements to support free services and improve user experience</p>

                                    <ul>
                                        <li>Type of Advertisements: We display a mix of non-personalized and personalized ads. Non-personalized ads are not based on user behavior, while personalized ads are aligned with user interests, derived from data like browsing history and app interactions.</li>
                                        <li>User Consent and Age Verification: Users aged 13-17 must provide verifiable parental consent to process personal data for personalized advertising. Users aged 18 and above can consent independently. Our app includes mechanisms for age verification and parental consent collection.</li>
                                        <li>Data Collection and User Privacy: While we gather information such as device specifics, app usage statistics, and, with consent, some behavioral data, we prioritize user privacy. We employ robust data protection and encryption methods to safeguard this information. We do not collect or share personally identifiable information without explicit user consent.</li>
                                        <li>Third-Party Advertising and Data Sharing: Our third-party advertising partners help us display relevant ads. These entities may access aggregated, anonymized data about user interactions within the app for better ad targeting. We carefully select these partners and ensure they adhere to stringent data protection standards.</li>
                                        <li>User Rights and Preferences: Users have rights concerning their data, including access, rectification, and deletion rights. We provide clear instructions within the app for managing data preferences and opting out of personalized ads. Opting out will lead to non-personalized ads only.</li>
                                        <li>Ad Performance and User Feedback: We regularly evaluate ad performance to ensure relevance and appropriateness. User feedback is crucial in this process, and we encourage feedback through in-app tools.</li>
                                        <li>Policy Updates and Communication: Our advertising policy may be updated to reflect changes in legal requirements or our practices. Users will be notified of significant changes via app notifications or email. We encourage users to review these updates.</li>
                                    </ul>

                                    <p>By using VGYANI - The Test Prep App, users agree to the terms outlined in this advertising policy. We are dedicated to maintaining a balance between providing a free, high-quality educational service and respecting our users' privacy and data preferences.</p>

                                    <h4>Use of cookies</h4>

                                    <p>We, our third party service providers and our partners may send "cookies" to your computer or use similar
                                        technologies to enhance your online experience at our platform. "Cookies" are files that can identify you as
                                        a unique customer and store your personal preferences as product preferences to tells us which platform
                                        pages you have visited and in what order. We use cookies and other technical information to personalise your
                                        visit to the platform, to analyse traffic on the platform and to track user trends, patterns and selections
                                        for authorised download and for technical reasons connected with your use of our platform.</p>

                                    <p>Cookies can either be permanent (i.e. they remain on Your computer until you delete them) or temporary (i.e.
                                        they last only until you close your browser). We may store both temporary or permanent ‘cookies’ on Your
                                        computer/device to store certain data (that is not sensitive Personal Information). You can erase or choose
                                        to block these cookies from your computer. You can configure your computer’s browser to alert you when we
                                        attempt to send you a cookie with an option to accept or refuse the cookie. If you have turned cookies off,
                                        you may be prevented from using certain features of the platform.</p>

                                    <p>You are always free to decline our cookies if your device permits, although in that case you may not be able
                                        to use certain features on the platform.</p>
                                    <p>We do not control the use of cookies by third parties.</p>

                                    <h4>Security</h4>

                                    <p>The security of your Personal Information is important to us. We have adopted reasonable security practices
                                        and procedures including role-based access, secure communication, password protection, encryption, etc. to
                                        ensure that the Personal Information collected is secure.</p>

                                    <p>We restrict access to your Personal Information to us and our affiliates’ employees, agents, third party
                                        service providers, partners, and agencies who need to know such Personal Information in relation to the
                                        Purposes as specified above in this Privacy Policy, provided that such entities agree to abide by this
                                        Privacy Policy.</p>

                                    <p>We have also implemented information security practices and standards and have in place information security
                                        programmes and policies containing managerial, technical, operational and physical security measures that
                                        are in compliance with the applicable laws including, so as to protect the information provided to us from
                                        unauthorized access, use, modification, damage, disclosure or impairment. Further, We may from time to time
                                        use reasonable additional or alternative procedures to ensure the security and confidentiality of Your
                                        Personal Information through our Investment Platform.</p>

                                    <p>While we will endeavour to take all reasonable and appropriate steps to keep secure any information which we
                                        hold about you and prevent unauthorized access, you acknowledge that the internet is not 100% secure. No
                                        data transmission over the internet is fully secure, so we cannot ensure or warrant the security of any
                                        information you submit to us. Further, we do not guarantee in any way, the security of any information that
                                        you transmit or share on the platform; and you do so at your own risk.</p>

                                    <p>If you think that the security of your account has been compromised, change your password and contact us
                                        immediately.</p>

                                    <h4>Third party links</h4>

                                    <p>During your interactions with us, it may happen that we provide/include links and hyperlinks of third-party
                                        web platforms not owned or managed by us ("Third-party platforms"). It may also happen that you or other
                                        Users may include links and hyperlinks of third-party platforms. The listing of such third-party web
                                        platforms (by you, other Users or by Us) does not imply endorsement of such third-party web platforms by
                                        Vgyani-The Test Prep App. Such third-party web platforms are governed by their own terms and conditions and
                                        when you access such Third-party platforms, you will be governed by the terms of such third-party web
                                        platforms. You must use your own discretion while accessing or using third-party web platforms. We do not
                                        make any representations regarding the availability and performance of any of the third-party web platforms.
                                        We are not responsible for the content, terms of use, privacy policies and practices of such third-party web
                                        platforms. We do not bear any liability arising out of your use of third-party web platforms.</p>

                                    <h4>Access</h4>

                                    <p>If you need to update your Personal Information or have any grievance with respect to the processing or use
                                        of your Personal Information, or request that we no longer use your Personal Information to provide you
                                        services, or opt-out of receiving communications such as promotional and marketing-related information
                                        regarding the services, for any reason, You may send Us an email at help.vgyani@gmail.com and we will take
                                        all reasonable efforts to incorporate the changes within a reasonable period of time.</p>

                                    <h4>Compliance with law</h4>

                                    <p>The contents of this Privacy Policy are governed by the laws of India. You are not allowed to use the
                                        services of the platform if any of the terms of this Privacy Policy are not in accordance with the
                                        applicable laws of your country.</p>

                                    <p>Some of our Services allow you to upload, submit, store, send or receive content. When you upload, submit,
                                        store, publish, send or receive content to or through our Services, you provide us with your consent to
                                        utilise such content, including for our marketing purposes, subject to our Privacy Policy.</p>

                                    <p>Such content must not:</p>
                                    <ul>
                                        <li> Be harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive
                                            of another’s privacy, hateful or racially or ethnically objectionable, disparaging, and relating to, or
                                            encouraging money laundering or gambling.</li>
                                        <li> Harm minors in any way.</li>
                                        <li> Infringe any patent, trademark, copyright or other intellectual property rights.</li>
                                        <li> Deceive or mislead the reader about the origin of such messages or communicate any information that is
                                            grossly offensive or menacing in nature.</li>
                                        <li> Contain software viruses or any other programs designed to interrupt, destroy or limit the
                                            functionality of another computer or mobile device.</li>
                                        <li> Threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with
                                            foreign states, or public order.</li>
                                        <li> Cause the incitement to commit any offence or prevent the investigation of any offence.</li>
                                        <li> Be insulting to any other nation.</li>
                                        <li> Impersonate another person.</li>
                                        <li> Violate the provisions of the Information Technology Act, 2000 and rules and regulations thereunder or
                                            any other applicable laws.</li>
                                    </ul>
                                    <h4>Term of storage of personal information</h4>
                                    <ul>
                                        <li> Vgyani-The Test Prep App may keep records of communications, including phone calls received and made
                                            for making enquiries, orders, feedback or other purposes for rendering services effectively and
                                            efficiently. Vgyani-The Test Prep App will be the exclusive owner of such data and records. However, all
                                            records are regarded as confidential. Therefore, will not be divulged to any third party, unless
                                            required by law.</li>
                                        <li> Vgyani-The Test Prep App will store your Personal Information at least for a reasonable time from the
                                            last date of use of the services, platform or for such period as may be required by law.</li>
                                        <li> You are responsible for maintaining the accuracy of the information you submit to us, such as your
                                            contact information provided as part of account registration. If your personal information changes, you
                                            may correct, delete inaccuracies, or amend information by making the change on account details page
                                            inside settings option or by contacting us through help.vgyani@gmail.com . We will make good faith
                                            efforts to make requested changes in our then active databases as soon as reasonably practicable. If you
                                            provide any information that is untrue, inaccurate, out of date or incomplete (or becomes untrue,
                                            inaccurate, out of date or incomplete), or Vgyani-The Test Prep App has reasonable grounds to suspect
                                            that the information provided by you is untrue, inaccurate, out of date or incomplete, Vgyani-The Test
                                            Prep App may, at its sole discretion, discontinue the provision of the services to you. There may be
                                            circumstances where Vgyani-The Test Prep App will not correct, delete or update your Personal
                                            Information, including (a) where the Personal Information is opinion data that is kept solely for
                                            evaluative purpose; and (b) the Personal Information is in documents related to a prosecution if all
                                            proceedings relating to the prosecution have not been completed.</li>
                                    </ul>
                                    <h4>Changes to the privacy policy</h4>
                                    <ul>
                                        <li>We reserve the right to update (change, modify, add and/or delete) the terms of this Privacy Policy from
                                            time to time, at our sole discretion, to reflect company initiatives, changes in the law or technology
                                            or changes in our practices regarding the collection and use of Personal Information.</li>
                                        <li>When we update our Privacy Policy, we will intimate you of the amendments on your mobile number
                                            registered with us or on the platform. If you do not agree to the amendments, please do not use the
                                            platform or services any further. By continuing to use the platform, you will be deemed to have
                                            consented to the latest version of the Privacy Policy.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <CtaLayoutOne /> */}
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;