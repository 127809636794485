import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import PricingData from "../../data/pricing/PricingData.json";
import { FaCheck} from "react-icons/fa";


const getPriceingData = PricingData;

const billed = [
    {
        id: 1,
        label: "Billed Yearly"
    }
]

const PricingOne = () => {

    const [isActive, setActive] = useState("");

    useEffect(() => {
        setActive(billed[0].label.toLowerCase());
		
    }, []);


    const handleChange = (e) => {
        setActive(e.target.textContent.toLowerCase());
        
    };

    const onPlayClick = () => {
        const url = "http://vgyani-unity-game.s3-website.eu-north-1.amazonaws.com/";
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const handleClick = (data) => {
        if(data.id == 1){
            onPlayClick();
        }

        if(data.id == 3){
            const element = document.getElementById("footerArea");
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    return (
        <>
            <div className="row">
                {getPriceingData.map((data, index) => (
                    <div className="col-lg-4 d-flex" key={index}>
                        <div className="pricing-table pricing-borderd col-12">
                            <div className="pricing-header">
                                <h3 className="title">{data.title}</h3>
                                <span className="subtitle">{data.subtitle}</span>
                                <div className="price-wrap">
                                    <div className="yearly-pricing">
                                        <span className="amount"> {data.symbol ? <span style={{fontFamily: "sans-serif"}}>₹</span> : ""} {billed[0].label.toLowerCase() === isActive ? data.yprice : data.mprice }</span>
                                        <span className="duration">{billed[0].label.toLowerCase() === isActive ? data.yduration : data.mduration }</span>
                                    </div>
                                </div>
                                <div className="pricing-btn">
                                    <button onClick={() => handleClick(data)} className="axil-btn btn-large btn-borderd">{data.btntext}</button>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-unstyled">
                                    {data.facility.map((data, index) => (
                                        <li key={index}><FaCheck /> {data}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PricingOne;