import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Thank you for contacting us! <br></br> It generally takes us 5-7 days to reply
        </Alert>
    )
}

const FormOne = () => {

    const form = useRef();
    const name = useRef();
    const email = useRef();
    const subject = useRef();
    const message = useRef();

    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        const payload = {
            "name": name.current.value,
            "email": email.current.value,
            "subject": subject.current.value,
            "message": message.current.value
        }

        axios.post("http://13.50.137.153:3000/api/misc/sendEmail", payload).then(data => {
            console.log("then ", data);
        }).catch(error => {
            console.log("catch ", error);
        })
        form.current.reset();
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            <div className="form-group">
                <div className='row'>
                    <div className='col'>
                        <label>Name</label>
                        <input type="text" className="form-control" name="contact-name" ref={name} required />
                    </div>

                    <div className='col'>
                        <label>Email</label>
                        <input type="email" className="form-control" name="contact-email" ref={email} required />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label>Subject</label>
                <input type="text" className="form-control" name="contact-subject" ref={subject} required />
            </div>
            <div className="form-group mb--40">
                <label>Message</label>
                <textarea cols={4} className="form-control" name='contact-message' ref={message}></textarea>
            </div>
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Submit</button>
            </div>
            <div className="form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}

export default FormOne;