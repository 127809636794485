import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const RefundAndCancellationPolicy = () => {

    return (
        <>
            <SEO title="Refund & Cancellation Policy" />
            {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Refund & Cancellation Policy"
                    page="Refund & Cancellation Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">This Refund and Cancellation policy was published on May 24, 2024.</h5>
                                    </div>

                                    <h4>Cancellation & Refund Policy</h4>

                                    <p>VGYANI EDUTAINMENT SERVICES PRIVATE LIMITED believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:</p>

                                    <ul>
                                        <li>Cancellations will be considered only if the request is made within 15 days of payment of the subscription fee. However, the cancellation request may not be entertained if raised after 15 days..</li>
                                        <li>In case you feel that the product received is not as shown or as per your expectations, you must bring it to the notice of our customer service within 15 Days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
                                        <li>In case of any Refunds approved by the VGYANI EDUTAINMENT SERVICES PRIVATE LIMITED, it’ll take 3-5 Days days for the refund to be processed to the end customer.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <CtaLayoutOne /> */}
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default RefundAndCancellationPolicy;